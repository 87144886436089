<script>
    import FileExtensionIcon from "./fileExtensionIcon"
    import TaskFileContext from "./context-menu/task-file-context"
    import {downloadArquivoTask, deleteArquivoTask} from "@/domain/tarefas/services"
    import {humanStorageSize} from "uloc-vue/src/utils/format";
    export default {
      name: 'task-file',
      props: ['file', 'h', 'task'],
      data () {
        return {}
      },
      computed: {},
      mounted () {
        this.$nextTick(() => {
          let name = this.$refs.name
          let extension = this.$refs.extension.$el
          let extensionWidth = (extension.offsetWidth + 5) + 'px'
          let size = this.$refs.size
          let sizeWidth = (size.offsetWidth + 5) + 'px'
          name.style.paddingLeft = extensionWidth
          name.style.paddingRight = sizeWidth
        })
      },
      destroyed () {
      },
      methods: {
        download(arquivo, inline = false) {
          arquivo.downloading = true
          let method = (a, b) => downloadArquivoTask({id: this.task ? this.task.id : this.h.entityId}, a, b)
          method(arquivo.id, inline)
              .then(response => {
                console.log(response.data)
                const link = document.createElement('a')
                link.href = response.data.url
                link.target = '_blank'
                document.body.appendChild(link)
                link.click();
                window.setTimeout(() => {
                  document.body.removeChild(link)
                  arquivo.downloading = false
                }, 100)
                // window.open(response.data.url)
              })
              .catch(error => {
                arquivo.downloading = false
                this.alertApiError(error)
              })
        },
        filesize (size) {
          return humanStorageSize(size)
        },
        confirmaExcluir (file) {
          this.$uloc.dialog({
            title: 'Atenção',
            message: `Tem certeza que deseja excluir o arquivo ${file.originalFilename}?`,
            cancel: true,
            color: 'secondary'
          }).then(data => {
            this.$uloc.notify({color: 'warning', message: 'Excluindo mensagem'})
            this.excluir(file)
          }).catch(() => {
          })
        },
        excluir (file) {
          if (!file) return
          deleteArquivoTask({id: this.task?.id || this.h.entityId}, file.id)
              .then(response => {
                console.log(response)
                this.$el.style.display = 'none'
                this.$uloc.notify({color: 'positive', message: 'Mensagem excluída!'})
                this.$emit('updateHistory')
              })
              .catch(error => {
                this.alertApiError(error)
              })
        }
      },
      components: {TaskFileContext, FileExtensionIcon}
    }
</script>

<template>
    <a @click="download(file)" class="file">
        <file-extension-icon ref="extension" :extension="file.extension"/>
        <span ref="name" class="fileName">{{file.originalFilename}}</span>
        <span ref="size" class="fileSize">({{filesize(file.size)}})</span>
        <task-file-context @download="download(file)" @preview="download(file, true)" @remove="confirmaExcluir(file)" :h="h" :file="file"/>
    </a>
</template>
