<script>
    import {UContextMenu} from 'uloc-vue'
    // import {isOwner} from '@/domain/usuario/helpers/Session'
    export default {
      name: 'task-file-context',
      props: ['file', 'h'],
      data () {
        return {}
      },
      computed: {
        me () {
          return this.$uloc.auth.session.user.person
        },
        isOwner () {
          if (!this.h) {
            return this.$uloc.auth.session.user.name === this.file.createdByName
          }
          return this.me === Number(this.h.personId)
        }
      },
      methods: {},
      components: {UContextMenu}
    }
</script>

<template>
    <u-context-menu class="window-context-menu">
        <ul>
            <li>
                <a @click="$emit('download')" class="menuItem">
                    <u-icon name="file-download" type="fa" icon-style="light" color="" class="m-r-sm fa-fw"></u-icon>
                    <strong>Baixar</strong>
                </a>
            </li>
          <li>
                <a @click="$emit('preview')" class="menuItem">
                    <u-icon name="eye" type="fa" icon-style="light" color="" class="m-r-sm fa-fw"></u-icon>
                    <strong>Pré-visualizar</strong>
                </a>
            </li>
<!--            <li>
                <a class="menuItem"> &lt;!&ndash; @TODO &ndash;&gt;
                    <u-icon name="share-square" type="fa" color="" class="m-r-sm fa-fw"></u-icon>
                    Compartilhar
                </a>
            </li>-->
            <li v-if="isOwner"> <!-- @TODO: <Raphael> -->
                <a class="menuItem" @click="$emit('remove')">
                    <u-icon name="trash-alt" type="fa" color="negative" class="m-r-sm fa-fw"></u-icon>
                    Excluir
                </a>
            </li>
        </ul>
    </u-context-menu>
</template>
