<script>
    // import {UTooltip} from "uloc-vue";
    import TaskAttach from "../attachments/attach"
    import TaskAttachmentsFiles from "../attachments/files"
    import TaskMixin from "@/components/sltarefas/components/tarefa/tabs/mixin"

    export default {
      name: 'task-attachments-tab',
      mixins: [TaskMixin],
      data () {
        return {
          files: []
        }
      },
      computed: {
        classes () {
          let css = []

          return css
        },
      },
      mounted () {
      },
      destroyed () {
      },
      methods: {},
      components: {TaskAttachmentsFiles, TaskAttach}
    }
</script>

<template>
    <div class="task-attachments col-grow-1 wrapper-lg" :class="classes">
        <task-attach :task="task"/>
        <task-attachments-files :task="task"/>
    </div>
</template>
